<template>
  <v-form v-model="valid" @submit.prevent="register">
    <v-card
      class="mx-3 mx-sm-auto py-3 px-6 my-8 my-sm-12"
      max-width="400"
      elevation="3"
    >
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="username"
              :rules="[(v) => !!v || 'Username is required']"
              label="Username"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="email"
              type="email"
              :rules="emailRules"
              label="E-Mail"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="password1"
              type="password"
              :rules="[(v) => !!v || 'Password is required']"
              label="Password"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="password2"
              type="password"
              :rules="[(v) => !!v && v == this.password1]"
              label="Password"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-btn @click="register" type="register" block color="primary">
        Register
      </v-btn>
      <v-container class="d-flex justify-center">
        <small class=""
          >Already have an account? Login
          <router-link to="/login">here.</router-link></small
        >
      </v-container>
    </v-card>
  </v-form>
</template>

<!-- TODO: give feedback, when Login credentials are wrong -->

<script>
export default {
  name: "Register",
  data: () => ({
    valid: false,
    username: "",
    password1: "",
    password2: "",
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
  }),
  methods: {
    register() {
      this.$axios
        .post("http://localhost:8000/api/users/register/", {
          username: this.username,
          email: this.email,
          password: this.password1,
        })
        .then(this.$router.push({ name: "Home" }));
    },
  },
};
</script>
